.MenuButton {
  width: 100%;
  display: flex;
  margin-bottom: 2px;
  margin-bottom: 2px;
  align-items: center;
  background-color: #fff;
}

.MenuButton:hover {
  background-color: #c4b5c9;
}

.MenuButtonIcon {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 50%;
  margin-right: 10px;
}

.MenuButtonArrow {
  color: #919191;
  margin-right: 10px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
