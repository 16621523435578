.podcastContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}
.title {
  margin-left: 10px;
}
