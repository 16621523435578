.HeaderLayout {
  height: 60px;
  width: 100%;
  background-image: linear-gradient(
    rgba(184, 171, 190, 1),
    rgba(250, 250, 250, 0.5)
  );
}

.HeaderContainer {
  display: flex;
  align-items: center;
}

.HeaderTitle {
  color: white;
  font-weight: bolder;
  font-size: 18px;
}

.HeaderLogo {
  height: 35px;
  cursor: pointer;
}

.HeaderIcons {
  display: flex;
  align-items: center;
  color: white;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}
