.container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tileWrapper{
    width: 95%;
    height: 50px;
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 10px 0px;
}

.leadingcomp{
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.sectionWrapper{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
}

.coinName{
    margin-left: 10px;
    font-weight: 'bold';
    font-size: 14px;
}
.balance{
    margin-left: 10px;
    font-weight: '400';
    font-size: 12px;

}

.messageContainer{
    margin-top: 20px;
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.messageTitle{
    font-size: 1rem;
    font-weight:bold;
}

.message{
    font-size: 0.8rem;
    margin-top: 10px;
    text-align: center;
}

.buttonContainer{
    background-color: #451e53;
    border-radius: 3px;
    padding: 3px 5px;
    font-size: 0.8rem;
    margin-top: 10px;
}
