.container {
    width: 100%;
}

.headerComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.titleComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #d9d4d4;
    font-size: 15px;
    font-weight: bold;
}

.languageRow {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button {
    font-size: 0.8rem;
    padding: 5px;
    color: #451e53;
    font-weight: bold;
    border: 0.5px solid lightgray;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
    box-shadow: 1px 1px #c4b5c9;
}

.bottomSheetButtons {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f0f0f0;
    justify-content: center;
    text-align: center;
    padding: 4px 0;
    border-top: 1px solid #ccc;
}

.label {
    cursor: pointer;
    margin-left: 5px;
}