.button {
  font-size: 0.8rem;
  padding: 5px;
  color: #451e53;
  font-weight: "bold";
  background-color: #ddcbe3;
  border-radius: 5px;
}

.focused {
  background-color: #c4b5c9;
  outline: 2px solid #b88ec6;
}

.divTitleSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 7px;
  margin-right: 7px;
}

.divTitleRow {
  font-size: 16px;
  font-weight: bold;
}

.divSelectedLanguageRow {
  font-size: 14px;
  color: gray;
}
