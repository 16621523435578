.container {
  width: 100%;
}

.discoverContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.feedContainer {
  width: 90%;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid lightgrey;
  margin-bottom: 30px;
  box-shadow: 1.5px 1.5px lightgray;
}

.feedBanner {
  height: auto;
  max-height: 150px;
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.bodySection {
  width: 100%;
}

.button {
  font-size: 0.8rem;
  padding: 5px;
  color: #451e53;
  background-color: #fff;
  font-weight: bold;
  border: 0.5px solid lightgray;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  box-shadow: 1px 1px #c4b5c9;
}

.button:hover {
  background-color: #c4b5c9;
}

.feedTitle {
  font-size: 1rem;
  font-weight: bold;
  margin: 5px 5px;
}

.author {
  font-size: 0.8rem;
  margin: 0px 5px;
  color: #451e53;
}

.feedDiscrption {
  font-size: 0.8rem;
  margin: 5px 5px;
}

.toolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid lightgrey;
  align-items: center;
  height: 40px;
}

.toolBarText {
  font-size: 0.8rem;
  margin: 0px 10px;
  color: #451e53;
  font-weight: bold;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.divTitleSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 7px;
  margin-right: 7px;
}

.divTitleRow {
  font-size: 16px;
  font-weight: bold;
}

.divSelectedLanguageRow {
  font-size: 14px;
  color: gray;
}
