.navContainer {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.navDiv {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  background-color: white;
}

.paddingEven {
  padding-top: 10px;
  padding-bottom: 5px;
  text-decoration: none;
}

.iconStyling {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconLabel {
  margin-top: 3px;
  font-size: 0.6rem;
  color: purple;
  text-decoration: none;
}

.iconWrapper {
  height: 25px;
  width: 25px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  font-size: 0.8rem;
  padding: 5px;
  color: #451e53;
  font-weight: bold;
  border: 0.5px solid lightgray;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  box-shadow: 1px 1px #c4b5c9;
}
