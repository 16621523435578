.topTenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.button {
  font-size: 0.8rem;
  padding: 5px;
  color: #451e53;
  font-weight: "bold";
  background-color: #c4b5c9;
  border-radius: 5px;
}