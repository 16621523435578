.button {
  font-size: 0.8rem;
  padding: 5px;
  color: #451e53;
  font-weight: "bold";
  background-color: #ddcbe3;
  border-radius: 5px;
}

.focused {
  background-color: #c4b5c9;
  outline: 2px solid #b88ec6;
}
