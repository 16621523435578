.headerComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ProfileCard {
  display: flex;
  flex-direction: column;
  background-color: #ededed;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1rem;
  padding: 5px 10vw;
  text-align: center;
}

.RoundCard {
  height: 100px;
  width: 100px;
  background-color: white;
  border-radius: 50%;
  margin-bottom: 10px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProfileImage {
  height: 50px;
  width: 50px;
}

.Info {
  display: flex;
  justify-content: space-around;
}

.InfoDetail {
  display: flex;
  font-size: 0.8rem;
  flex-direction: column;
  justify-content: space-around;
}

.NameStyle {
  font-weight: bold;
  margin: 0px;
  margin-bottom: 2px;
  font-size: 0.8rem;
}

.EmailStyle {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  text-align: center;
  font-size: 0.8rem;
}

.HrStyle {
  margin-top: 2px;
  margin-bottom: 2px;
  border: 1px solid #eae7e7;
  width: 100%;
}

.Bold {
  font-weight: bold;
}
