.container{
    /* width: 95%; */
    display: flex;
    height: 50px;
    align-items: center;
    border-top: 1px solid lightgrey;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 5px 5px;
}
.verticalContainer{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}
.leadComp{
    height: 25px;
    width: 25px;
    margin-left: 5px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bodyTitle{
    font-size: 0.8rem;
    /* margin-left: 0px; */
    color: black;
    /* font-weight: bold; */
    flex: 1;
}
.bodyPoints{
    background-color: white;
    margin-right: 10px;
    font-size: 0.8rem;
}
.text{
    margin-left: 3px;
    font-size: 1.2rem;
}

.bodyContainer{
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    flex: 1;
    width: 100%;
}
.tokenText{
    font-size: 0.55rem;
    color: gray;
    /* margin-left: 0px; */
}