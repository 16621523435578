.loadContainer{
    position: fixed;
    /* top: 0;
    left:0; */
    /* width: 100%;
    height: auto; */
    align-content: center;
    background-color: #c4b5c9;
}
.loader{
    /* width: 100%; */
    /* left: 50%; */
    top: 30%;
    /* z-index: 1000; */
    display: flex;
    flex-direction: column;
    /* justify-items: center; */
    align-items: center;
}