.container {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  line-height: 1.63;
  font-family: "Circular Std Book";
  -webkit-font-smoothing: antialiased;
  color: #232323;
  margin: 5px;
  padding: 10px;
  border-radius: 5px !important;
  box-shadow: 0 0 5px 2px #efefef !important;
}

.Row {
  font-size: 12px;
  padding: 5px;
  display: flex;
  flex-direction: row;
}

.MainTitle {
  font-size: 15px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 0 !important;
}

.divTermsAndCondition h3 {
  font-size: 20px;
  line-height: 1.29;
}

.divTermsAndCondition h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  word-wrap: break-word;
  margin-bottom: 10px;
}

.divTermsAndCondition p {
  margin-bottom: 30px !important;
  word-wrap: break-word !important;
}

.divTermsAndCondition ul {
  margin-left: 5px !important;
  padding-left: 10px !important;
}
