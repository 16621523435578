.cardBody {
  color: #262626;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-bottom: 5px;
  width: 90px;
  margin: 10px 0px;
  transition: background-color 0.3s, color 0.3s;
}

.cardBody:hover {
  background-color: #451e53;
  color: #fff;
}

.cardTitle {
  width: 100%;
  text-align: center;
  font-size: 0.5rem;
}
