.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.headerComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
}
.backButton {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin: 5px;
  cursor: pointer;
}
.episodeTitle {
  font-size: 0.9rem;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.podcastImage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}
.podcastBanner {
  width: 90px;
  height: 90px;
  border-radius: 8px;
  box-shadow: 1.5px 1.5px lightgray;
}
.likeButton {
  cursor: pointer;
  display: flex;
  background-color: #fff;
  border: 0.5px solid lightgray;
  border-radius: 5px;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  box-shadow: 1px 1px #c4b5c9;
}
.likeButton:hover {
  background-color: #c4b5c9;
}
.likeImage {
  height: 20px;
  width: 20px;
  float: left;
  margin-right: 2px;
}
.likeButton {
  color: #262626;
  font-size: 0.9rem;
  float: right;
}

.podcastDescription {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.shortDescription {
  font-size: 0.8rem;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mediaContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 20px;
}
.controlContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  /* margin-left: 25px; */
}
.playerWrapper {
  margin: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.playerWrapper:hover {
  transform: scale(0.98);
  background-color: #c4b5c9;
  border: 1px solid #451e53;
  cursor: pointer;
}
.playButton {
  height: 20px;
  width: 20px;
  padding: 5px;
  box-shadow: 1.5px 1.5px lightgray;
}

.volumeButton {
  height: 25px;
  width: 25px;
  padding: 5px;
  box-shadow: 1.5px 1.5px lightgray;
}

.title {
  font-size: 8vw;
  font-weight: bold;
  color: tomato;
  margin-top: 20px;
}
.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.erMsg {
  font-size: 7vw;
  font-weight: 400;
  color: tomato;
  margin-top: 10px;
  text-align: center;
  max-width: 95%;
}
.errorWrapper {
  width: 90%;
  height: 80%;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.progressBarContainer {
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.duration {
  font-size: 0.8rem;
  color: #451e53;
  font-weight: "bold";
}
