.AddPodcastLayout {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headerComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
}

.PodcastInput {
  margin-top: 5px;
}

.PodcastButton {
  padding: 20px 0px 20px 0px;
  display: flex;
  justify-content: center;
}

.TermsText {
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
}

.TermsLink {
  padding-left: 2px;
}
