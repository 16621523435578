.headerComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
}
.container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProfileCard {
  border-radius: 5px;
  margin-top: 10px;
  font-size: 15px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 0 5px 2px #efefef;
}

.HrStyle {
  margin-top: 2px;
  margin-bottom: 2px;
  border: 1px solid #efefef;
  width: 100%;
}

.NameStyle {
  font-weight: bold;
  margin: 0px;
  margin-bottom: 2px;
}

.EmailStyle {
  display: block;
  white-space: nowrap;
  width: 11rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  text-align: center;
}

.CustomHeading {
  text-align: left;
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 0;
}
