.container{
    width: 100%;
}

.iframeWindow{
    background: url('../../assets/spinner.gif');
    background-repeat: no-repeat;
    background-size: 100px 100px;
    background-position: top;
    border: none;
    outline: none;
    overflow:hidden;
    height: 2000vh;
    width: 100%;
}