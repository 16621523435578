.container{
    width: 92%;
    display: flex;
    height: 40px;
    align-items: center;
    border-top: 1px solid lightgrey;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 5px 0px;
}

.leadComp{
    height: 30px;
    width: 30px;
    margin:0px 5px ;
}

.bodyTitle{
    font-size: 0.8rem;
    margin-left: 10px;
}