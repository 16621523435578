.container {
  width: 100%;
}
.headerComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.backButton {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin: 3px;
  cursor: pointer;
}
.podcastImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  margin-bottom: 10px;
}
.podcastBanner {
  height: 80px;
  width: 80px;
  border-radius: 8px;
  box-shadow: 1.5px 1.5px lightgray;
}
.imgSpinner {
  height: 40px;
  width: 40px;
  /* border-radius: 8px; */
  /* box-shadow: 1.5px 1.5px lightgray; */
}
.podcastToolbar {
  display: flex;
  width: 95%;
  justify-content: space-around;
}
.metaDataBox {
  display: flex;
  border: 0.5px solid lightgray;
  border-radius: 5px;
  box-shadow: 1px 1px #c4b5c9;
}
.followBox {
  cursor: pointer;
  display: flex;
  background-color: #fff;
  border: 0.5px solid lightgray;
  border-radius: 5px;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  box-shadow: 1px 1px #c4b5c9;
}
.followBox:hover {
  background-color: #c4b5c9;
}
.detailContainer {
  display: flex;
  flex-direction: column;
  height: 30px;
  align-items: center;
  margin: 0px 3px;
}
.lightText {
  font-size: 0.7rem;
}
.boldText {
  font-size: 13px;
  font-weight: bold;
}

.description {
  flex-direction: column;
  display: flex;
  margin: 5px 10px;
  width: 90%;
}
.episodeHeaderComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.episodeList {
  flex-direction: column;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.episodeTile {
  width: 95%;
  background-color: #fff;
  border: 0.5px solid #451e53;
  min-height: 35px;
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  box-shadow: 1px 1px #c4b5c9;
}
.episodeList:hover .episodeTile {
  background-color: #c4b5c9;
}
.episodeTitle {
  font-size: 1rem;
  display: flex;
  flex: 1;
}
.playIcon {
  margin: 0px 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.button {
  font-size: 0.8rem;
  padding: 5px;
  color: #451e53;
  font-weight: "bold";
  background-color: #fff;
  border: 0.5px solid #451e53;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.button:hover {
  background-color: #c4b5c9;
  border: 1px solid #451e53;
}
