.SignInLayout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SignInButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  box-shadow: 0 0 5px 2px #f2eaea;
  background-color: #faf8f8;
  padding: 5px 5px;
  width: 100%;
}

.btnWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.backdrop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorWrapper {
  width: 90%;
  height: 80%;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.title {
  font-size: 8vw;
  font-weight: bold;
  color: tomato;
  margin-top: 20px;
}

.erMsg {
  font-size: 7vw;
  font-weight: 400;
  color: tomato;
  margin-top: 10px;
  text-align: center;
  max-width: 95%;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.btnText {
  font-size: 5vw;
}

.button {
  display: flex;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-decoration: none;
  background: white;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70%;
}
.button:hover {
  border: 1px solid #451e53;
}
