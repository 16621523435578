.InputboxContainer {
    position: relative;
}

.InputBox {
    padding: 5px;
    width: 100%;
}

.InputBoxLabel {
    margin:15px 0;
    position:relative;
    display:inline-block;
}
    
.InputBoxSpan {
    padding:5px;
    pointer-events: none;
    position:absolute;
    left:5px;
    top:0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity:0.5;
}

.InputBox:focus, .InputBox:not(:placeholder-shown) {
    outline: none !important;
    border: 1px solid #451e53 !important;
}

.InputBox:focus, .InputBox:not(:-ms-input-placeholder) {
    outline: none !important;
    border: 1px solid #451e53 !important;
}
  
.InputBox:focus + .InputBoxSpan, .InputBox:not(:placeholder-shown) + .InputBoxSpan {
    opacity:1;
    transform: scale(0.75) translateY(-100%) translateX(-20px);
    color: #451e53;
}
  
/* For IE Browsers*/
.InputBox:focus + .InputBoxSpan, .InputBox:not(:-ms-input-placeholder) + .InputBoxSpan {
    opacity:1;
    transform: scale(0.75) translateY(-100%) translateX(-20px);
    color: #451e53;
}
