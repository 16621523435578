.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.backHeader{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    font-size: 0.8rem;
    margin-bottom: 20px;
}
.inputField{
    border:1px solid lightgray;
    border-radius: 5px;
    padding: 3px 5px;
    text-align: center;
    width: 80vw;
    height: 30px;
}
.btnWrapper{
    width: 100%;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.addButton{
    width: 60px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #451e53;
    color: white;
    font-size: 0.8rem;
    border-radius: 5px;
    margin-top: 20px;
}